import {
  ACCOUNT,
  DASHBOARD,
  SET,
  CHANNEL,
  STORE,
  STORE_LIST,
  VIEWERS,
  EVENTS,
  UNAUTHENTICATE,
  REQUEST,
} from '../common/dict';

import {
  createType,
  defineAsyncReducer,
  getInitState,
} from '../common/util';

const storeList = defineAsyncReducer(createType(DASHBOARD, STORE_LIST), 'store_list');

const viewersData = defineAsyncReducer(createType(DASHBOARD, VIEWERS), 'viewers');

const eventsData = defineAsyncReducer(createType(DASHBOARD, EVENTS), 'events');

const initialState = {
  channel: null,
  store: null,
  store_list: getInitState(),
  viewers: getInitState(),
  events: getInitState(),
};

const dashboard = (state = initialState, action) => {
  const { type, payload } = action;
  const initReducers = {
    [createType(UNAUTHENTICATE, REQUEST)]: () => initialState,
    [createType(SET, ACCOUNT)]: () => initialState,
    [createType(DASHBOARD, SET, CHANNEL)]: () => ({ ...state, channel: payload }),
    [createType(DASHBOARD, SET, STORE)]: () => ({ ...state, store: payload }),
  };
  const reducers = [storeList, viewersData, eventsData].reduce(
    (accumulator, currentValue) => currentValue(accumulator),
    initReducers,
  );
  if (type in reducers) {
    return reducers[type](state, action);
  }
  return state;
};

export default dashboard;
