import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { AUTHENTICATED } from '../../common/dict';
import { messages as msgs } from '../../common/messages';
import { locale_list } from '../../common/locale_list';
import DropDownList from '../../components/general/DropDownList';
import Button from '@material-ui/core/Button';

import { logout } from '../../actions/aaa';
import { setAccount, setLocale } from '../../actions/common';

const styles = {
  logout: {
    marginLeft: 0,
  },
};

class SettingArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const authentication = this.props.authentication;
    return (
      <>
        {authentication.status !== AUTHENTICATED ? null : (
          <DropDownList
            value={this.props.account}
            optionList={authentication.user.accounts}
            width={110}
            updateOption={this.props.setAccount}
          />
        )}
        <DropDownList
          value={this.props.locale}
          optionList={locale_list}
          width={90}
          updateOption={this.props.setLocale}
        />
        {authentication.status !== AUTHENTICATED ? null : (
          <Button variant="contained" style={styles.logout} onClick={this.props.logout}>
            {this.props.intl.formatMessage(msgs.logout)}
          </Button>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: state.shared.authentication,
    account: state.shared.account,
    locale: state.shared.locale,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setAccount: (payload) => dispatch(setAccount(payload)),
  setLocale: (payload) => dispatch(setLocale(payload)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingArea));

SettingArea.propTypes = {
  intl: intlShape.isRequired,
  authentication: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  account: PropTypes.string,
  setLocale: PropTypes.func.isRequired,
  setAccount: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};
