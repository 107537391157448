import React from 'react';
import { FormattedMessage } from 'react-intl';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '80vh',
    color: '#BDBDBD',
  },
};

const NoFound = () => (
  <div style={styles.container}>
    <p>
      <FormattedMessage
        id="common.pagenofound"
        defaultMessage="No page was found, please check the url."
      />
    </p>
  </div>
);

export default NoFound;
