import { combineEpics } from 'redux-observable';
import { createType, genFetchEpic, genFetchRegionEpic } from '../common/util';
import { PROFILE_ANALYT, REGION_LIST, VIEWERS, EVENTS } from '../common/dict';
import { regionListUri, profileInsightUri, rawProfileInsightUri } from '../actions/api';

const regionList = genFetchRegionEpic(createType(PROFILE_ANALYT, REGION_LIST),
                                      regionListUri);

const viewers = genFetchEpic(createType(PROFILE_ANALYT, VIEWERS), profileInsightUri);
const events = genFetchEpic(createType(PROFILE_ANALYT, EVENTS), rawProfileInsightUri);

const profile = combineEpics(regionList, viewers, events);

export default profile;
