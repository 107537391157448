import { AUTHENTICATE, AUTOAUTHENTICATE, UNAUTHENTICATE, REQUEST, ACCOUNT } from '../common/dict';
import { createType } from '../common/util';

export const login = (username, password) => ({
  type: createType(AUTHENTICATE, REQUEST),
  payload: { username, password },
});

export const autoLogin = () => ({ type: createType(AUTOAUTHENTICATE, REQUEST) });

export const logout = () => ({ type: createType(UNAUTHENTICATE, REQUEST) });

export const autoLogout = () => ({ type: createType(UNAUTHENTICATE, ACCOUNT) });
