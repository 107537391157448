import { combineEpics } from 'redux-observable';
import { createType, genFetchEpic } from '../common/util';
import { SECOND_ANALYT, CAMPAIGN_LIST, DATA } from '../common/dict';
import { campaignListUri, secondInsightUri } from '../actions/api';

const campaignList = genFetchEpic(createType(SECOND_ANALYT, CAMPAIGN_LIST), campaignListUri);

const data = genFetchEpic(createType(SECOND_ANALYT, DATA), secondInsightUri);

const second = combineEpics(campaignList, data);

export default second;
