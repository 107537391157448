import { defineMessages } from 'react-intl';

const common = defineMessages({
  colon: {
    id: 'common.colon',
    defaultMessage: ':',
  },
  age: {
    id: 'common.age',
    defaultMessage: 'Age',
  },
  gender: {
    id: 'common.gender',
    defaultMessage: 'Gender',
  },
  viewers: {
    id: 'common.viewers',
    defaultMessage: 'Views',
  },
  viewersWeekday: {
    id: 'common.viewersWeekday',
    defaultMessage: 'Views (Weekday)',
  },
  viewersWeekend: {
    id: 'common.viewersWeekend',
    defaultMessage: 'Views (Weekend)',
  },
  impressions: {
    id: 'common.impressions',
    defaultMessage: 'Impressions',
  },
  impressionsWeekday: {
    id: 'common.impressionsWeekday',
    defaultMessage: 'Impressions (Weekday)',
  },
  impressionsWeekend: {
    id: 'common.impressionsWeekend',
    defaultMessage: 'Impressions (Weekend)',
  },
  ots: {
    id: 'common.ots',
    defaultMessage: 'OTS',
  },
  mediaOTS: {
    id: 'common.mediaOTS',
    defaultMessage: 'Media OTS',
  },
  conversionRate: {
    id: 'common.conversionRate',
    defaultMessage: 'Conversion Rate',
  },
  totalViewers: {
    id: 'common.totalViewers',
    defaultMessage: 'Total Views',
  },
  totalImpressions: {
    id: 'common.totalImpressions',
    defaultMessage: 'Total Impressions',
  },
  totalOTS: {
    id: 'common.totalOTS',
    defaultMessage: 'Total OTS',
  },
  totalMediaOTS: {
    id: 'common.totalMediaOTS',
    defaultMessage: 'Total Media OTS',
  },
  demography: {
    id: 'common.demography',
    defaultMessage: 'Demography',
  },
  demographicDistribution: {
    id: 'common.demographicDistribution',
    defaultMessage: 'Demographic Distribution',
  },
  summary: {
    id: 'common.summary',
    defaultMessage: 'Summary',
  },
  ranking: {
    id: 'common.ranking',
    defaultMessage: 'Ranking',
  },
  total: {
    id: 'common.total',
    defaultMessage: 'Total',
  },
  name: {
    id: 'common.name',
    defaultMessage: 'Name',
  },
  analyze: {
    id: 'common.analyze',
    defaultMessage: 'Analyze',
  },
  loading: {
    id: 'common.loading',
    defaultMessage: 'Loading',
  },
  error: {
    id: 'common.error',
    defaultMessage: 'Error',
  },
  channel: {
    id: 'common.channel',
    defaultMessage: 'Channel',
  },
  region: {
    id: 'common.region',
    defaultMessage: 'Region',
  },
  mediaInfo: {
    id: 'common.mediaInfo',
    defaultMessage: 'Media Info',
  },
  secs: {
    id: 'common.secs',
    defaultMessage: 'secs',
  },
  selected: {
    id: 'common.selected',
    defaultMessage: 'Selected',
  },
  all: {
    id: 'common.all',
    defaultMessage: 'All',
  },
  reportPeriod: {
    id: 'common.reportPeriod',
    defaultMessage: 'Report Period',
  },
  secUnit: {
    id: 'common.secUnit',
    defaultMessage: 's',
  },
});

const hint = defineMessages({
  viewersOTSHint: {
    id: 'hint.viewersOTSHint',
    defaultMessage: '* You can click legend at the right to hide and show selected data.',
  },
  noData: {
    id: 'hint.noData',
    defaultMessage: 'No Data',
  },
});

const selector = defineMessages({
  allChannels: {
    id: 'selector.allChannels',
    defaultMessage: 'All Channels',
  },
  allRegions: {
    id: 'selector.allRegions',
    defaultMessage: 'All Regions',
  },
  allCampaigns: {
    id: 'selector.allCampaigns',
    defaultMessage: 'All Media',
  },
  allDemographics: {
    id: 'selector.allDemographics',
    defaultMessage: 'All Demographics',
  },
  selectChannels: {
    id: 'selector.selectChannels',
    defaultMessage: 'Select Channels',
  },
  selectRegionsOrChannels: {
    id: 'selector.selectRegionsOrChannels',
    defaultMessage: 'Select Regions or Channels',
  },
  selectProfileType: {
    id: 'selector.selectProfileType',
    defaultMessage: 'Select Profile Type',
  },
  selectReportType: {
    id: 'selector.selectReportType',
    defaultMessage: 'Select Report Type',
  },
  selectCampaign: {
    id: 'selector.selectCampaign',
    defaultMessage: 'Select Media',
  },
  channelSelector: {
    id: 'selector.channelSelector',
    defaultMessage: 'Channel Selector',
  },
  regionSelector: {
    id: 'selector.regionSelector',
    defaultMessage: 'Region Selector',
  },
  poSelector: {
    id: 'selector.poSelector',
    defaultMessage: 'PO Selector',
  },
  campaignSelector: {
    id: 'selector.campaignSelector',
    defaultMessage: 'Media Selector',
  },
  campaignsSelector: {
    id: 'selector.campaignsSelector',
    defaultMessage: 'Media Selector',
  },
  channelsSelector: {
    id: 'selector.channelsSelector',
    defaultMessage: 'Channels Selector',
  },
  regionsSelector: {
    id: 'selector.regionsSelector',
    defaultMessage: 'Regions Selector',
  },
});

const chart = defineMessages({
  average: {
    id: 'chart.average',
    defaultMessage: 'average',
  },
  viewersOTS: {
    id: 'chart.viewersOTS',
    defaultMessage: 'Views/OTS by Day',
  },
  impressionsMediaOTS: {
    id: 'chart.impressionsMediaOTS',
    defaultMessage: 'Impressions/Media OTS by Day',
  },
  viewersByHour: {
    id: 'chart.viewersByHour',
    defaultMessage: 'Views by Hour',
  },
  viewersByDay: {
    id: 'chart.viewersByDay',
    defaultMessage: 'Views by Day',
  },
  viewersByWeekday: {
    id: 'chart.viewersByWeekday',
    defaultMessage: 'Views by Weekday',
  },
  impressionsByHour: {
    id: 'chart.impressionsByHour',
    defaultMessage: 'Impressions by Hour',
  },
  impressionsByDay: {
    id: 'chart.impressionsByDay',
    defaultMessage: 'Impressions by Day',
  },
  impressionsByWeekday: {
    id: 'chart.impressionsByWeekday',
    defaultMessage: 'Impressions by Weekday',
  },
  otsByHour: {
    id: 'chart.otsByHour',
    defaultMessage: 'OTS by Hour',
  },
  mediaOTSByHour: {
    id: 'chart.mediaOTSByHour',
    defaultMessage: 'Media OTS by Hour',
  },
  otsByDay: {
    id: 'chart.otsByDay',
    defaultMessage: 'OTS by Day',
  },
  MediaOTSByDay: {
    id: 'chart.MediaOTSByDay',
    defaultMessage: 'Media OTS by Day',
  },
  otsByWeekday: {
    id: 'chart.otsByWeekday',
    defaultMessage: 'OTS by Weekday',
  },
  MediaOTSByWeekday: {
    id: 'chart.MediaOTSByWeekday',
    defaultMessage: 'Media OTS by Weekday',
  },
  conversionRateByHour: {
    id: 'chart.conversionRateByHour',
    defaultMessage: 'Conversion Rate by Hour',
  },
  conversionRateByDay: {
    id: 'chart.conversionRateByDay',
    defaultMessage: 'Conversion Rate by Day',
  },
  conversionRateByWeekday: {
    id: 'chart.conversionRateByWeekday',
    defaultMessage: 'Conversion Rate by Weekday',
  },
  demoDistByHour: {
    id: 'chart.demoDistByHour',
    defaultMessage: 'Demographic Distribution by Hour',
  },
  demoDistByDay: {
    id: 'chart.demoDistByDay',
    defaultMessage: 'Demographic Distribution by Day',
  },
  demoDistByWeekday: {
    id: 'chart.demoDistByWeekday',
    defaultMessage: 'Demographic Distribution by Weekday',
  },
  viewersByDemographics: {
    id: 'chart.viewersByDemographics',
    defaultMessage: 'Views by Demographics',
  },
  viewersByGender: {
    id: 'chart.viewersByGender',
    defaultMessage: 'Views by Gender',
  },
  viewersByAge: {
    id: 'chart.viewersByAge',
    defaultMessage: 'Views by Age',
  },
  impressionsByDemographics: {
    id: 'chart.impressionsByDemographics',
    defaultMessage: 'Impressions by Demographics',
  },
  impressionsByGender: {
    id: 'chart.impressionsByGender',
    defaultMessage: 'Impressions by Gender',
  },
  impressionsByAge: {
    id: 'chart.impressionsByAge',
    defaultMessage: 'Impressions by Age',
  },
  genderPercentage: {
    id: 'chart.genderPercentage',
    defaultMessage: 'Gender Percentage',
  },
  agePercentage: {
    id: 'chart.agePercentage',
    defaultMessage: 'Age Percentage',
  },
});

const demographics = defineMessages({
  maleChild: {
    id: 'demographics.maleChild',
    defaultMessage: 'Male Child',
  },
  maleYoung: {
    id: 'demographics.maleYoung',
    defaultMessage: 'Male Young',
  },
  maleYoungAdult: {
    id: 'demographics.maleYoungAdult',
    defaultMessage: 'Male Young Adult',
  },
  maleAdult: {
    id: 'demographics.maleAdult',
    defaultMessage: 'Male Adult',
  },
  maleSenior: {
    id: 'demographics.maleSenior',
    defaultMessage: 'Male Senior',
  },
  'male13-17': {
    id: 'demographics.male13-17',
    defaultMessage: 'Male 13-17',
  },
  'male18-29': {
    id: 'demographics.male18-29',
    defaultMessage: 'Male 18-29',
  },
  'male30-49': {
    id: 'demographics.male30-49',
    defaultMessage: 'Male 30-49',
  },
  'male50-64': {
    id: 'demographics.male50-64',
    defaultMessage: 'Male 50-64',
  },
  'male65+': {
    id: 'demographics.male65+',
    defaultMessage: 'Male 65+',
  },
  femaleChild: {
    id: 'demographics.femaleChild',
    defaultMessage: 'Female Child',
  },
  femaleYoung: {
    id: 'demographics.femaleYoung',
    defaultMessage: 'Female Young',
  },
  femaleYoungAdult: {
    id: 'demographics.femaleYoungAdult',
    defaultMessage: 'Female Young Adult',
  },
  femaleAdult: {
    id: 'demographics.femaleAdult',
    defaultMessage: 'Female Adult',
  },
  femaleSenior: {
    id: 'demographics.femaleSenior',
    defaultMessage: 'Female Senior',
  },
  'female13-17': {
    id: 'demographics.female13-17',
    defaultMessage: 'Female 13-17',
  },
  'female18-29': {
    id: 'demographics.female18-29',
    defaultMessage: 'Female 18-29',
  },
  'female30-49': {
    id: 'demographics.female30-49',
    defaultMessage: 'Female 30-49',
  },
  'female50-64': {
    id: 'demographics.female50-64',
    defaultMessage: 'Female 50-64',
  },
  'female65+': {
    id: 'demographics.female65+',
    defaultMessage: 'Female 65+',
  },
  child: {
    id: 'demographics.child',
    defaultMessage: 'Child',
  },
  young: {
    id: 'demographics.young',
    defaultMessage: 'Young',
  },
  youngAdult: {
    id: 'demographics.youngAdult',
    defaultMessage: 'Young Adult',
  },
  adult: {
    id: 'demographics.adult',
    defaultMessage: 'Adult',
  },
  senior: {
    id: 'demographics.senior',
    defaultMessage: 'Senior',
  },
  '13-17': {
    id: 'demographics.13-17',
    defaultMessage: '13-17',
  },
  '18-29': {
    id: 'demographics.18-29',
    defaultMessage: '18-29',
  },
  '30-49': {
    id: 'demographics.30-49',
    defaultMessage: '30-49',
  },
  '50-64': {
    id: 'demographics.50-64',
    defaultMessage: '50-64',
  },
  '65+': {
    id: 'demographics.65+',
    defaultMessage: '65+',
  },
  male: {
    id: 'demographics.male',
    defaultMessage: 'Male',
  },
  female: {
    id: 'demographics.female',
    defaultMessage: 'Female',
  },
  maleChildShort: {
    id: 'demographics.maleChildShort',
    defaultMessage: 'M. Child',
  },
  maleYoungShort: {
    id: 'demographics.maleYoungShort',
    defaultMessage: 'M. Young',
  },
  maleYoungAdultShort: {
    id: 'demographics.maleYoungAdultShort',
    defaultMessage: 'M. Young Adult',
  },
  maleAdultShort: {
    id: 'demographics.maleAdultShort',
    defaultMessage: 'M. Adult',
  },
  maleSeniorShort: {
    id: 'demographics.maleSeniorShort',
    defaultMessage: 'M. Senior',
  },
  'male13-17Short': {
    id: 'demographics.male13-17Short',
    defaultMessage: 'M. 13-17',
  },
  'male18-29Short': {
    id: 'demographics.male18-29Short',
    defaultMessage: 'M. 18-29',
  },
  'male30-49Short': {
    id: 'demographics.male30-49Short',
    defaultMessage: 'M. 30-49',
  },
  'male50-64Short': {
    id: 'demographics.male50-64Short',
    defaultMessage: 'M. 50-64',
  },
  'male65+Short': {
    id: 'demographics.male65+Short',
    defaultMessage: 'M. 65+',
  },
  femaleChildShort: {
    id: 'demographics.femaleChildShort',
    defaultMessage: 'F. Child',
  },
  femaleYoungShort: {
    id: 'demographics.femaleYoungShort',
    defaultMessage: 'F. Young',
  },
  femaleYoungAdultShort: {
    id: 'demographics.femaleYoungAdultShort',
    defaultMessage: 'F. Young Adult',
  },
  femaleAdultShort: {
    id: 'demographics.femaleAdultShort',
    defaultMessage: 'F. Adult',
  },
  femaleSeniorShort: {
    id: 'demographics.femaleSeniorShort',
    defaultMessage: 'F. Senior',
  },
  'female13-17Short': {
    id: 'demographics.female13-17Short',
    defaultMessage: 'F. 13-17',
  },
  'female18-29Short': {
    id: 'demographics.female18-29Short',
    defaultMessage: 'F. 18-29',
  },
  'female30-49Short': {
    id: 'demographics.female30-49Short',
    defaultMessage: 'F. 30-49',
  },
  'female50-64Short': {
    id: 'demographics.female50-64Short',
    defaultMessage: 'F. 50-64',
  },
  'female65+Short': {
    id: 'demographics.female65+Short',
    defaultMessage: 'F. 65+',
  },
});

const weekdays = defineMessages({
  weekday: {
    id: 'weekdays.weekday',
    defaultMessage: 'Weekday',
  },
  weekend: {
    id: 'weekdays.weekend',
    defaultMessage: 'Weekend',
  },
  monday: {
    id: 'weekdays.monday',
    defaultMessage: 'MONDAY',
  },
  tuesday: {
    id: 'weekdays.tuesday',
    defaultMessage: 'TUESDAY',
  },
  wednesday: {
    id: 'weekdays.wednesday',
    defaultMessage: 'WEDNESDAY',
  },
  thursday: {
    id: 'weekdays.thursday',
    defaultMessage: 'THURSDAY',
  },
  friday: {
    id: 'weekdays.friday',
    defaultMessage: 'FRIDAY',
  },
  saturday: {
    id: 'weekdays.saturday',
    defaultMessage: 'SATURDAY',
  },
  sunday: {
    id: 'weekdays.sunday',
    defaultMessage: 'SUNDAY',
  },
});

const timePeriod = defineMessages({
  hintStartDate: {
    id: 'date.hintStartDate',
    defaultMessage: 'From (Earliest 6 months)',
  },
  hintEndDate: {
    id: 'date.hintEndDate',
    defaultMessage: 'To (Max. window 1 month)',
  },
});

const login = defineMessages({
  titleLogin: {
    id: 'login.titleLogin',
    defaultMessage: 'Login',
  },
  username: {
    id: 'login.username',
    defaultMessage: 'Username',
  },
  password: {
    id: 'login.password',
    defaultMessage: 'Password',
  },
  login: {
    id: 'login.login',
    defaultMessage: 'Login',
  },
  errMsg: {
    id: 'login.errMsg',
    defaultMessage: 'Username or password is incorrect',
  },
  invalidToken: {
    id: 'login.invalidToken',
    defaultMessage: 'Your session has expired. Please log in again to continue.',
  },
});

const menu = defineMessages({
  menuDashboard: {
    id: 'menu.menuDashboard',
    defaultMessage: 'Dashboard',
  },
  menuRegion: {
    id: 'menu.menuRegion',
    defaultMessage: 'By Geographic Regions',
  },
  menuTime: {
    id: 'menu.menuTime',
    defaultMessage: 'Time Based Analyses',
  },
  menuProfile: {
    id: 'menu.menuProfile',
    defaultMessage: 'Region/Channel Profile',
  },
  menuCampaign: {
    id: 'menu.menuCampaign',
    defaultMessage: 'Media Analyses',
  },
  menuSecond: {
    id: 'menu.menuSecond',
    defaultMessage: 'Audience Attention',
  },
  menuOperation: {
    id: 'menu.menuOperation',
    defaultMessage: 'Operation',
  },
  menuPOReport: {
    id: 'menu.menuPOReport',
    defaultMessage: 'PO Report',
  },
  logout: {
    id: 'menu.logout',
    defaultMessage: 'Logout',
  },
});

const dashboard = defineMessages({
  rollingThreeMonths: {
    id: 'dashboard.rollingThreeMonths',
    defaultMessage: 'Rolling Three Months',
  },
  yesterday: {
    id: 'dashboard.yesterday',
    defaultMessage: 'Yesterday',
  },
  devices: {
    id: 'dashboard.devices',
    defaultMessage: 'Devices',
  },
  topMedia: {
    id: 'dashboard.topMedia',
    defaultMessage: 'Top 10 Media',
  },
  topStores: {
    id: 'dashboard.topStores',
    defaultMessage: 'Top 10 Stores',
  },
  store: {
    id: 'dashboard.store',
    defaultMessage: 'Store',
  },
  dailyAverage: {
    id: 'dashboard.dailyAverage',
    defaultMessage: 'Daily Average',
  },
  reset: {
    id: 'dashboard.reset',
    defaultMessage: 'Back to Default View',
  },
  searchStore: {
    id: 'dashboard.searchStore',
    defaultMessage: 'Search Store',
  },
});

const region = defineMessages({
  totalRegions: {
    id: 'region.totalRegions',
    defaultMessage: 'Total Regions',
  },
  totalChannels: {
    id: 'region.totalChannels',
    defaultMessage: 'Total Channels',
  },
  numberOfStores: {
    id: 'region.numberOfStores',
    defaultMessage: 'No. Of Stores',
  },
});

const campaign = defineMessages({
  report: {
    id: 'campaign.report',
    defaultMessage: 'Report',
  },
  info: {
    id: 'campaign.info',
    defaultMessage: 'Info',
  },
  media: {
    id: 'campaign.media',
    defaultMessage: 'Media',
  },
  campaignPeriod: {
    id: 'campaign.campaignPeriod',
    defaultMessage: 'Media Period',
  },
  targetViewersNumber: {
    id: 'campaign.targetViewersNumber',
    defaultMessage: 'Target Views Number',
  },
  targetViewersReached: {
    id: 'campaign.targetViewersReached',
    defaultMessage: 'Target Views Reached',
  },
  opportunitiesToSee: {
    id: 'campaign.opportunitiesToSee',
    defaultMessage: 'Opportunities To See',
  },
  playCount: {
    id: 'campaign.playCount',
    defaultMessage: 'Play Count',
  },
  impressionsPerPlay: {
    id: 'campaign.impressionsPerPlay',
    defaultMessage: 'Impressions Per Play',
  },
  print: {
    id: 'campaign.print',
    defaultMessage: 'Print',
  },
  weeklyStatistics: {
    id: 'campaign.weeklyStatistics',
    defaultMessage: 'Weekly Statistics',
  },
  reportCreated: {
    id: 'campaign.reportCreated',
    defaultMessage: 'Report Created',
  },
});

const second = defineMessages({
  campaignLength: {
    id: 'second.campaignLength',
    defaultMessage: 'Length',
  },
  category: {
    id: 'second.category',
    defaultMessage: 'Category',
  },
  frameThumbnail: {
    id: 'second.frameThumbnail',
    defaultMessage: 'Frame Thumbnail',
  },
});

const po = defineMessages({
  poPeriod: {
    id: 'po.poPeriod',
    defaultMessage: 'PO Created in',
  },
  targetAudience: {
    id: 'po.targetAudience',
    defaultMessage: 'Target Audience',
  },
  targetViewNumber: {
    id: 'po.targetViewNumber',
    defaultMessage: 'Target View Number',
  },
  targetViewReached: {
    id: 'po.targetViewReached',
    defaultMessage: 'Target View Reached',
  },
  spotCount: {
    id: 'po.spotCount',
    defaultMessage: 'Spot Count',
  },
  impressionsPerSpot: {
    id: 'po.impressionsPerSpot',
    defaultMessage: 'Views Per Spot',
  },
  accViewNumber: {
    id: 'po.accViewNumber',
    defaultMessage: 'Accumulated View Number',
  },
  accCompletionRate: {
    id: 'po.accCompletionRate',
    defaultMessage: 'Accumulated Completion Rate',
  },
  hour: {
    id: 'po.hour',
    defaultMessage: 'Hour',
  },
  taOnly: {
    id: 'po.taOnly',
    defaultMessage: 'Target Audience Only',
  },
  campaignPeriod: {
    id: 'po.campaignPeriod',
    defaultMessage: 'Campaign Period',
  },
  generalReport: {
    id: 'po.generalReport',
    defaultMessage: 'General Report',
  },
  overview: {
    id: 'po.overview',
    defaultMessage: 'Overview',
  },
});

const footer = defineMessages({
  copyright: {
    id: 'footer.copyright',
    defaultMessage: 'Confidential. All Rights Reserved. PTV Holdings',
  },
});

export const messages = Object.assign(
  {},
  common,
  hint,
  selector,
  chart,
  demographics,
  weekdays,
  timePeriod,
  login,
  menu,
  dashboard,
  region,
  campaign,
  second,
  po,
  footer,
);
