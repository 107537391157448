import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { createType, genFetchEpic, fetchResponse } from '../common/util';
import {
  CAMPAIGN_ANALYT,
  CAMPAIGN_LIST,
  CHANNEL_LIST,
  DATA,
  REQUEST,
  RESPONSE,
} from '../common/dict';
import { campaignListUri, campaignChannelListUri, campaignInsightUri } from '../actions/api';

const campaignList = genFetchEpic(createType(CAMPAIGN_ANALYT, CAMPAIGN_LIST), campaignListUri);

const campaignChannelList = (action$) =>
  action$.pipe(
    ofType(createType(CAMPAIGN_ANALYT, CHANNEL_LIST, REQUEST)),
    switchMap((action) =>
      fetchResponse(campaignChannelListUri(action.payload.campaign), {
        Account: action.payload.Account,
      }).pipe(
        map((res) => ({
          type: createType(CAMPAIGN_ANALYT, CHANNEL_LIST, RESPONSE),
          payload: res.payload,
        })),
        catchError((err) =>
          of({
            type: createType(CAMPAIGN_ANALYT, CHANNEL_LIST, RESPONSE),
            error: true,
            payload: err.xhr.response,
          }),
        ),
      ),
    ),
  );

const campaignData = genFetchEpic(createType(CAMPAIGN_ANALYT, DATA), campaignInsightUri);

const campaign = combineEpics(campaignList, campaignChannelList, campaignData);

export default campaign;
