import { DateTime } from 'luxon';

import { messages } from './messages';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { autoLogout } from '../actions/aaa';
import { menuList } from './menu_list';

import {
  REQUEST,
  RESPONSE,
  INIT,
  LOADING,
  READY,
  ERROR,
  targetGenders,
  targetAgeGroups,
  AUTOAUTHENTICATE,
} from './dict';

export const getInitState = () => ({ status: INIT });
export const getLoadingState = () => ({ status: LOADING });
export const getReadyState = () => ({ status: READY });
export const getErrorState = () => ({ status: ERROR });

export const demographicPriority = {
  total: 100,
  male: 22,
  female: 21,
  '65+': 15,
  '50-64': 14,
  '30-49': 13,
  '18-29': 12,
  '13-17': 11,
  senior: 15,
  adult: 14,
  youngAdult: 13,
  young: 12,
  child: 11,
  'male65+': 10,
  'male50-64': 9,
  'male30-49': 8,
  'male18-29': 7,
  'male13-17': 6,
  maleSenior: 10,
  maleAdult: 9,
  maleYoungAdult: 8,
  maleYoung: 7,
  maleChild: 6,
  'female13-17': 5,
  'female18-29': 4,
  'female30-49': 3,
  'female50-64': 2,
  'female65+': 1,
  femaleChild: 5,
  femaleYoung: 4,
  femaleYoungAdult: 3,
  femaleAdult: 2,
  femaleSenior: 1,
};

export const zipToObj = (keys, values) => {
  let obj = {};
  keys.forEach((key, i) => {
    obj[key] = values[i];
  });
  return obj;
};

export const createType = (...args) => args.join('/');

export const querystring = (obj) =>
  Object.keys(obj)
    .map((key) => {
      if (Array.isArray(obj[key])) {
        let encKey = encodeURIComponent(key);
        return obj[key].map((x) => `${encKey}=${encodeURIComponent(x)}`).join('&');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    })
    .join('&');

export const setIfNotNull = (obj, key, value) => {
  if (value !== null) {
    obj[key] = value;
  }
  return obj;
};

export const formatDate = (dt) => dt.toISODate();

export const getToday = () => DateTime.local().startOf('day');

export const threeMonths = () => {
  let today = getToday();
  return {
    StartDate: formatDate(today.minus({ months: 3 })),
    EndDate: formatDate(today),
  };
};

export const lastWeek = () => {
  let today = getToday();
  return {
    StartDate: formatDate(today.minus({ weeks: 1 })),
    EndDate: formatDate(today),
  };
};

export const defineAsyncReducer = (prefix, key) => (reducers) =>
  Object.assign(reducers, {
    [createType(prefix, REQUEST)]: (state) => ({ ...state, [key]: getLoadingState() }),
    [createType(prefix, RESPONSE)]: (state, action) => {
      const { error, payload } = action;
      if (error) {
        return {
          ...state,
          [key]: { ...getErrorState(), error: payload.message },
        };
      }
      return {
        ...state,
        [key]: { ...getReadyState(), data: payload },
      };
    },
  });

export const getOptionName = (list, option, defaultName = null) => {
  let optionName = defaultName;

  if (list.status === 'READY') {
    list.data.some((o) => {
      if (o.id === option) {
        optionName = o.name;
        return true;
      }
      return false;
    });
  }
  return optionName;
};

export const camelCase = (s1, s2) => {
  s2 = s2[0].toUpperCase() + s2.slice(1);
  return `${s1}${s2}`;
};

export const demographicTotalOrder = (a, b) => {
  let n = 0;
  let totalOrder = {};
  for (let gender of targetGenders) {
    for (let ageGroup of targetAgeGroups) {
      totalOrder[camelCase(gender, ageGroup)] = n;
      n++;
    }
  }
  return totalOrder[a] - totalOrder[b];
};

export const sumViewer = (list) => {
  let v = {};
  if (list.length === 0) {
    return v;
  }
  const sum = (x) => x.reduce((acc, value) => acc + value, 0);
  targetGenders.forEach((gender) => {
    targetAgeGroups.forEach((ageGroup) => {
      let key = camelCase(gender, ageGroup);
      let total = sum(list.map((x) => x[key]));
      if (!Number.isNaN(total)) {
        v[key] = total;
      }
    });
  });
  v.viewer = sum(list.map((x) => x.viewer));
  v.from = list[0].name;
  v.to = list[list.length - 1].name;
  return v;
};

const demo2age = () => {
  let mapping = {};
  targetGenders.forEach((gender) => {
    targetAgeGroups.forEach((ag) => {
      let key = camelCase(gender, ag);
      mapping[key] = ag;
    });
  });
  return mapping;
};

export const demoAGMapping = demo2age();

export const sumViewerByAG = (days, ageGroups) => {
  let summary = {}, total = 0;
  // init
  for (let ag of ageGroups) {
    summary[ag] = 0;
  }
  for (let day of days) {
    for (let key in day) {
      if (!(key in demoAGMapping)) {
        continue;
      }
      summary[demoAGMapping[key]] += day[key];
      total += day[key];
    }
  }
  return {summary, total};
};

export const daysToPeriod = (days) => {
  let endDate = getToday();
  let startDate = endDate.minus({ days });
  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  };
};

export const aggreWeekly = (action) => {
  const { error, payload } = action;
  if (
    error ||
    !payload.day ||
    payload.day.status !== 'OK' ||
    !Array.isArray(payload.day.payload) ||
    payload.day.payload.length < 1
  ) {
    return [];
  }

  const splitAt7 = (array) => {
    if (array.length <= 7) {
      return [array];
    }
    let a = splitAt7(array.slice(7));
    a.unshift(array.slice(0, 7));
    return a;
  };
  return splitAt7(payload.day.payload).map(sumViewer);
};

export const addIdtoRepeatedName = (data) => {
  let dic = {};
  let res = [];
  data.forEach((obj, i) => {
    let item = dic[obj.name];
    res.push(Object.assign({}, obj));
    if (item) {
      res[i].name = `${obj.name} (${obj.id})`;
      if (!item.addedId) {
        let p = item.position;
        res[p].name = `${data[p].name} (${data[p].id})`;
        item.addedId = true;
      }
    } else {
      dic[obj.name] = {
        position: i,
        addedId: false,
      };
    }
  });
  return res;
};

export const getMsgs = (id) => {
  if (messages[id]) {
    return messages[id];
  } else {
    return {
      id: id,
      defaultMessage: id,
    };
  }
};

export const addColon = (title, formattedFn) => {
  if (formattedFn) {
    return `${formattedFn(getMsgs(title))}${formattedFn(getMsgs('colon'))}`;
  } else {
    return `${title}:`;
  }
};

var withCredentials = false;
if (process.env.NODE_ENV === 'development') {
  withCredentials = true;
}

export const fetchResponse = (baseUri, query = null) => {
  let uri = query ? `${baseUri}?${querystring(query)}` : baseUri;
  let headers = {
    'Content-Type': 'application/json',
    'X-Requested-By': 'XMLHttpRequest',
  };
  return ajax({
    url: uri,
    headers,
    withCredentials,
    responseType: 'json',
  }).pipe(map((x) => x.response));
};

export const fetchPostResponse = (baseUri, data = null) => {
  let headers = {
    'Content-Type': 'application/json',
    'X-Requested-By': 'XMLHttpRequest',
  };
  return ajax({
    url: baseUri,
    body: data,
    method: 'POST',
    headers,
    withCredentials,
  });
};

export const genFetchEpic = (prefix, baseUri) => (action$) =>
  action$.pipe(
    ofType(createType(prefix, REQUEST)),
    switchMap((action) =>
      fetchResponse(baseUri, action.payload).pipe(
        map((res) => ({
          type: createType(prefix, RESPONSE),
          payload: res.payload,
        })),
        catchError((err) =>
          isAuthError(err, prefix)
            ? of(autoLogout())
            : of({
                type: createType(prefix, RESPONSE),
                error: true,
                payload: err.xhr.response || { message: err.xhr.statusText || err.xhr.status },
              }),
        ),
      ),
    ),
  );

export const genFetchRegionEpic = (prefix, baseUri) => (action$) =>
  action$.pipe(
    ofType(createType(prefix, REQUEST)),
    switchMap((action) =>
      fetchResponse(baseUri, action.payload).pipe(
        map((res) => {
          let regions = [];
          res.payload.forEach((area) => (regions = regions.concat(area.payloads)));
          return {
            type: createType(prefix, RESPONSE),
            payload: regions,
          };
        }),
        catchError((err) =>
          isAuthError(err, prefix)
            ? of(autoLogout())
            : of({
                type: createType(prefix, RESPONSE),
                error: true,
                payload: err.xhr.response,
              }),
        ),
      ),
    ),
  );

export const genFetchPostEpic = (prefix, uri) => (action$) =>
  action$.pipe(
    ofType(createType(prefix, REQUEST)),
    switchMap((action) =>
      fetchPostResponse(uri, action.payload).pipe(
        map((res) => ({
          type: createType(prefix, RESPONSE),
          payload: res.response.payload,
        })),
        catchError((err) =>
          isAuthError(err, prefix)
            ? of(autoLogout())
            : of({
                type: createType(prefix, RESPONSE),
                error: true,
                payload: err.xhr.response || { message: err.xhr.statusText || err.xhr.status },
              }),
        ),
      ),
    ),
  );

export const getChannelList = (state) => state.channel_mapping[state.account];

export const getChannelIDs = (chlist) => chlist.data.map((x) => x.id);

export const initInterval = (func) => {
  const { StartDate, EndDate } = lastWeek();
  func(StartDate, EndDate);
};

// Plus timezone offset to convert UTC date 00:00 to local date 00:00
export const stringToJSDate = (str) => {
  const d = new Date(str);
  return new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1_000);
};

const isAuthError = (err, prefix) => err.xhr.status === 401 && prefix !== AUTOAUTHENTICATE;

export const getUserPages = (user) => {
  let pages;
  if (user.admin) {
    pages = Object.keys(menuList);
  } else {
    pages = user.pages.filter((page) => page in menuList);
  }
  return pages;
};
