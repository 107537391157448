import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const DropDownList = (props) => {
  const styles = {
    container: {
      width: props.width,
      marginRight: 10,
    },
  };

  let disabled = props.disabled || false;
  let listItems = props.optionList.map((option) => (
    <MenuItem key={option.id} value={option.id} style={props.value === option.id ? { color: 'red' } : {}}>
      {option.name}
    </MenuItem>
  ));

  return (
    <Select
      style={styles.container}
      value={props.value}
      disabled={disabled}
      onChange={(e) => props.updateOption(e.target.value)}
    >
      {listItems}
    </Select>
  );
};

export default DropDownList;

DropDownList.propTypes = {
  disabled: PropTypes.bool,
  optionList: PropTypes.array.isRequired,
  optionAll: PropTypes.string,
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  updateOption: PropTypes.func.isRequired,
};
