import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { getMsgs } from '../common/util';

const styles = {
  footer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'inherit',
    minWidth: 'inherit',
    height: 30,
    padding: 5,
    bottom: 0,
    fontSize: '0.8em',
    boxSizing: 'border-box',
  },
};

const Footer = (props) => {
  const msg = props.intl.formatMessage(getMsgs('copyright'));
  return (
    <div style={styles.footer} className={'nonprintable'}>
      <p>{msg}</p>
    </div>
  );
};

export default injectIntl(Footer);

Footer.propTypes = {
  intl: intlShape.isRequired,
};
