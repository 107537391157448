import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Toolbar from '@material-ui/core/Toolbar';
import { AUTHENTICATED } from '../../common/dict';
import Logo from '../../components/Menu/Logo';
import MenuItems from '../../components/Menu/MenuItems';
import SettingArea from './SettingArea';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    backgroundColor: '#A3E6E8',
    marginBottom: 20,
    paddingLeft: 0,
    '@media (min-width: 600px)': {
      minHeight: '56px',
    },
  },
  empty: {
    marginRight: 'auto',
  },
};

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const authentication = this.props.authentication;
    const logoPath = authentication.status === AUTHENTICATED ? authentication.user.logoPath : null;

    return (
      <div className={'nonprintable'}>
        <Toolbar className={this.props.classes.container}>
          <Logo url={logoPath} />
          <MenuItems pathname={this.props.pathname} authentication={this.props.authentication} />
          <div style={styles.empty} />
          <SettingArea />
        </Toolbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: state.shared.authentication,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Menu));

Menu.propTypes = {
  pathname: PropTypes.string.isRequired,
  authentication: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
