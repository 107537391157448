import { combineReducers } from 'redux';

import campaign from './campaign';
import dashboard from './dashboard';
import profile from './profile';
import region from './region';
import shared from './shared';
import time_interval from './time_interval';
import second from './second';
import operation from './operation';
import poreport from './poreport';

const rootReducer = combineReducers({
  shared,
  dashboard,
  region,
  time_interval,
  profile,
  campaign,
  second,
  operation,
  poreport,
});

export default rootReducer;
