import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MenuItemsFull from './MenuItemsFull';
import { AUTHENTICATED } from '../../common/dict';
import { menuList } from '../../common/menu_list';
import { getUserPages } from '../../common/util';
import { messages as msgs } from '../../common/messages';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';

const MenuItems = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const authentication = props.authentication;
  if (authentication.status !== AUTHENTICATED) {
    return null;
  }

  let menus = [];
  let contentSize = 105 + 335; // logo + setting area

  const pages = getUserPages(authentication.user);
  for (let key of pages) {
    contentSize += 120;
    menus.push({
      name: props.intl.formatMessage(msgs[menuList[key].nameID]),
      link: menuList[key].path,
      order: menuList[key].order,
    });
  }
  menus.sort((a, b) => a.order - b.order);

  if (contentSize > window.innerWidth) {
    // small menu
    return (
      <>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose} onClick={handleClose}>
          {menus.map((item, key) => {
            if (item.link === props.pathname) {
              return (
                <MenuItem value={key} key={key} style={{ color: '#FF4081' }}>
                  {item.name}
                </MenuItem>
              );
            }
            return (
              <MenuItem value={key} key={key} component={Link} to={item.link}>
                {item.name}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  } else {
    return <MenuItemsFull pathname={props.pathname} data={menus} />;
  }
};

export default injectIntl(MenuItems);

MenuItems.propTypes = {
  intl: intlShape.isRequired,
  pathname: PropTypes.string.isRequired,
  authentication: PropTypes.object.isRequired,
};
