import { combineEpics } from 'redux-observable';
import { genFetchEpic, genFetchPostEpic } from '../common/util';
import { AUTOAUTHENTICATE, AUTHENTICATE, UNAUTHENTICATE } from '../common/dict';
import { loginUri, logoutUri, userInfoUri } from '../actions/api';

const login = genFetchPostEpic(AUTHENTICATE, loginUri);

const logout = genFetchPostEpic(UNAUTHENTICATE, logoutUri);

const userInfo = genFetchEpic(AUTOAUTHENTICATE, userInfoUri);

const aaa = combineEpics(login, logout, userInfo);

export default aaa;
