import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
const styles = {
  btn: {
    borderRadius: 0,
    height: 56,
    margin: 0,
    width: 120,
    lineHeight: 1.3,
    textAlign: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    boxSizing: 'border-box',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#4D9DAC',
    },
  },
  selected: { backgroundColor: '#4D9DAC', color: 'white !important' },
};

const MenuItemsFull = (props) => {
  return props.data.map((item, key) => {
    if (item.link === props.pathname) {
      return (
        <Button className={`${props.classes.btn} ${props.classes.selected}`} disabled key={key}>
          {item.name}
        </Button>
      );
    }

    return (
      <Button className={props.classes.btn} component={Link} to={item.link} key={key}>
        {item.name}
      </Button>
    );
  });
};

export default withStyles(styles)(MenuItemsFull);

MenuItemsFull.propTypes = {
  pathname: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};
