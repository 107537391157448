import { combineEpics } from 'redux-observable';
import { createType, genFetchRegionEpic, fetchResponse, zipToObj } from '../common/util';
import {
  TIME_ANALYT,
  REGION_LIST,
  VIEWERS,
  EVENTS,
  CHANNEL,
  REGION,
  REQUEST,
  RESPONSE,
} from '../common/dict';
import { regionListUri, timeInsightUri, rawTimeInsightUri } from '../actions/api';

import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { forkJoin, zip, throwError } from 'rxjs/index';

const regionList = genFetchRegionEpic(createType(TIME_ANALYT, REGION_LIST),
                                      regionListUri);

const fetchBase = (noun, uri) => (action$) =>
  action$.pipe(
    ofType(createType(TIME_ANALYT, noun, REQUEST)),
    switchMap((action) => {
      let optionIDs;
      if (action.payload.type === CHANNEL) {
        optionIDs = action.payload.channels;
      } else if (action.payload.type === REGION) {
        optionIDs = action.payload.regions;
      }
      return zip(
        of(optionIDs),
        forkJoin(
          optionIDs.map((id) => {
            let query = {
              Account: action.payload.account,
              StartDate: action.payload.startDate,
              EndDate: action.payload.endDate,
            };
            if (action.payload.type === CHANNEL) {
              query.Channel = id;
            } else if (action.payload.type === REGION) {
              query.Region = id;
              query.Channel = action.payload.channels;
            }
            return fetchResponse(uri, query).pipe(
              map((res) => res.payload),
              catchError((err) => throwError(new Error(err.xhr.response.message))),
            );
          }),
        ),
      );
    }),
    map((data) => ({
      type: createType(TIME_ANALYT, noun, RESPONSE),
      payload: zipToObj(data[0], data[1]),
    })),
    catchError((err) =>
      of({
        type: createType(TIME_ANALYT, noun, RESPONSE),
        error: true,
        payload: err,
      }),
    ),
  );

export const viewers = fetchBase(VIEWERS, timeInsightUri);
const events = fetchBase(EVENTS, rawTimeInsightUri);

const time = combineEpics(regionList, viewers, events);

export default time;
