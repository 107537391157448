import {
  TIME_ANALYT,
  SET,
  CHANNEL,
  CHANNELS,
  REGION,
  REGIONS,
  REGION_LIST,
  INTERVAL,
  SELECTOR,
  ACCOUNT,
  VIEWERS,
  EVENTS,
  UNAUTHENTICATE,
  REQUEST,
} from '../common/dict';

import {
  createType,
  defineAsyncReducer,
  getInitState,
} from '../common/util';

const regionList = defineAsyncReducer(createType(TIME_ANALYT, REGION_LIST),
                                      'region_list');

const viewers = defineAsyncReducer(createType(TIME_ANALYT, VIEWERS), 'viewers');
const events = defineAsyncReducer(createType(TIME_ANALYT, EVENTS), 'events');

const initialState = {
  selector: CHANNEL,
  channels: [],
  regions: [],
  interval: null,
  region_list: getInitState(),
  viewers: getInitState(),
  events: getInitState(),
};

const timeInterval = (state = initialState, action) => {
  const { type, payload } = action;
  const initialValue = {
    [createType(UNAUTHENTICATE, REQUEST)]: () => initialState,
    [createType(SET, ACCOUNT)]: () => initialState,
    [createType(TIME_ANALYT, SET, SELECTOR, CHANNEL)]: () => ({ ...state, selector: CHANNEL }),
    [createType(TIME_ANALYT, SET, SELECTOR, REGION)]: () => ({ ...state, selector: REGION }),
    [createType(TIME_ANALYT, SET, CHANNELS)]: () => ({ ...state, channels: payload }),
    [createType(TIME_ANALYT, SET, REGIONS)]: () => ({ ...state, regions: payload }),
    [createType(TIME_ANALYT, SET, INTERVAL)]: () => ({ ...state, interval: payload }),
  };

  const reducers = [regionList, viewers, events].reduce(
    (accumulator, currentValue) => currentValue(accumulator),
    initialValue,
  );
  if (type in reducers) {
    return reducers[type](state, action);
  }
  return state;
};

export default timeInterval;
