import { Settings } from 'luxon';
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import ja from 'react-intl/locale-data/ja';
import zh from 'react-intl/locale-data/zh';

import Menu from './Menu/Menu';
import Footer from '../components/Footer';
import Routes from '../components/RouteContainer/Routes';
import { autoLogin } from '../actions/aaa';

import translations from '../translations';
import '../css/general.css';
import 'c3/c3.css';

addLocaleData([...ja]);
addLocaleData([...zh]);

const styles = {
  root: {
    position: 'relative',
    width: '100%',
    minWidth: 600,
    minHeight: '95vh',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingBottom: 30,
  },
  content: {
    width: 'inherit',
    minWidth: 'inherit',
    minHeight: 50,
    overflow: 'auto',
  },
};

class RouteContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const locale = this.props.locale;
    if ('user' in this.props.authentication && 'timezone' in this.props.authentication.user) {
      Settings.defaultZoneName = this.props.authentication.user.timezone;
    }
    return (
      <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
        <div style={styles.root}>
          <Menu pathname={this.props.location.pathname} />
          <div style={styles.content}>
          <Suspense fallback={<div />}>
            <Routes
              pathname={this.props.location.pathname}
              auth={this.props.authentication}
              autoLogin={this.props.autoLogin}
            />
          </Suspense>
          </div>
          <Footer />
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.shared.authentication,
  locale: state.shared.locale,
});

const mapDispatchToProps = { autoLogin };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteContainer));

RouteContainer.propTypes = {
  locale: PropTypes.string.isRequired,
  authentication: PropTypes.object.isRequired,
  autoLogin: PropTypes.func.isRequired,
};
