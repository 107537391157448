import {
  SECOND_ANALYT,
  SET,
  CAMPAIGN,
  CAMPAIGN_LIST,
  ACCOUNT,
  DATA,
  SECOND,
  INTERVAL,
  PERIOD,
  UNAUTHENTICATE,
  REQUEST,
} from '../common/dict';

import {
  createType,
  defineAsyncReducer,
  getInitState,
} from '../common/util';

const campaignList = defineAsyncReducer(createType(SECOND_ANALYT, CAMPAIGN_LIST), 'campaign_list');

const second_data = defineAsyncReducer(createType(SECOND_ANALYT, DATA), 'second_data');

const initialState = {
  campaign: undefined,
  second: undefined,
  campaign_list: getInitState(),
  second_data: getInitState(),
  interval: null,
  period: undefined,
};

const second = (state = initialState, action) => {
  const { type, payload } = action;
  const initialValue = {
    [createType(UNAUTHENTICATE, REQUEST)]: () => initialState,
    [createType(SET, ACCOUNT)]: () => initialState,
    [createType(SECOND_ANALYT, SET, CAMPAIGN)]: () => ({ ...state, campaign: payload }),
    [createType(SECOND_ANALYT, SET, INTERVAL)]: () => ({ ...state, interval: payload }),
    [createType(SECOND_ANALYT, SET, PERIOD)]: () => ({ ...state, period: payload }),
    [createType(SECOND_ANALYT, SET, SECOND)]: () => ({ ...state, second: payload }),
  };
  const reducers = [campaignList, second_data].reduce(
    (accumulator, currentValue) => currentValue(accumulator),
    initialValue,
  );
  if (type in reducers) {
    return reducers[type](state, action);
  }
  return state;
};

export default second;
