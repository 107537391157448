import React from 'react';
import { FormattedMessage } from 'react-intl';

const Error = () => (
  <div className={'defaultItemContainer'}>
    <p className={'defaultMsg'}>
      <FormattedMessage
        id="error.errMsg"
        defaultMessage="Something got wrong, please try again later :("
      />
    </p>
  </div>
);

export default Error;
