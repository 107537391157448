import { lazy } from 'react';
const Dashboard = lazy(() => import('../containers/Dashboard'));
const DashboardRaw = lazy(() => import('../containers/DashboardRaw'));
const Region = lazy(() => import('../containers/Region'));
const Time = lazy(() => import('../containers/Time'));
const TimeRaw = lazy(() => import('../containers/TimeRaw'));
const Profile = lazy(() => import('../containers/Profile'));
const ProfileRaw = lazy(() => import('../containers/ProfileRaw'));
const Campaign = lazy(() => import('../containers/Campaign'));
const Second = lazy(() => import('../containers/Second'));
const Operation = lazy(() => import('../containers/Operation'));
const POReport = lazy(() => import('../containers/POReport'));

export const menuList = {
  dashboard: {
    path: '/dashboard',
    component: Dashboard,
    nameID: 'menuDashboard',
    order: 1,
  },
  dashboardRaw: {
    path: '/dashboard-raw',
    component: DashboardRaw,
    nameID: 'menuDashboard',
    order: 2,
  },
  region: {
    path: '/region',
    component: Region,
    nameID: 'menuRegion',
    order: 3,
  },
  time: {
    path: '/time',
    component: Time,
    nameID: 'menuTime',
    order: 4,
  },
  timeRaw: {
    path: '/time-raw',
    component: TimeRaw,
    nameID: 'menuTime',
    order: 5,
  },
  profile: {
    path: '/profile',
    component: Profile,
    nameID: 'menuProfile',
    order: 6,
  },
  profileRaw: {
    path: '/profile-raw',
    component: ProfileRaw,
    nameID: 'menuProfile',
    order: 7,
  },
  campaign: {
    path: '/campaign',
    component: Campaign,
    nameID: 'menuCampaign',
    order: 8,
  },
  second: {
    path: '/second',
    component: Second,
    nameID: 'menuSecond',
    order: 9,
  },
  operation: {
    path: '/operation',
    component: Operation,
    nameID: 'menuOperation',
    order: 10,
  },
  poreport: {
    path: '/poreport',
    component: POReport,
    nameID: 'menuPOReport',
    order: 11,
  },
};
