import { DateTime } from 'luxon';
import {
  OPERATION_ANALYT,
  SET,
  DATA,
  INTERVAL,
  ACCOUNT,
  UNAUTHENTICATE,
  REQUEST,
  RESPONSE,
} from '../common/dict';

import {
  createType,
  formatDate,
  getInitState,
  getErrorState,
  getReadyState,
  getLoadingState,
} from '../common/util';

const exportCSV = (data) => {
  let downloadLink = document.createElement('a');
  downloadLink.download = 'dataTable.csv';
  downloadLink.innerHTML = 'Download File';

  if (window.URL != null) {
    var code = encodeURIComponent(data);
    downloadLink.href = 'data:application/csv;charset=utf-8,%EF%BB%BF' + code;
  }
  downloadLink.click();
};

export const prepareData = (data, interval) => {
  let csvData;
  let firstRow = ',,,';
  let secondRow = 'Channel,Store Number,Store Name,';
  let startDate = DateTime.fromISO(interval.StartDate);
  let endDate = DateTime.fromISO(interval.EndDate);

  for (let dt = startDate; dt < endDate; dt = dt.plus({ days: 1 })) {
    let day = formatDate(dt);
    firstRow += `${day},${day},`;
    secondRow += `Viewers,OTS,`;
  }
  firstRow = firstRow.replace(/,$/i, '\r\n');
  secondRow = secondRow.replace(/,$/i, '\r\n');
  csvData = `${firstRow}${secondRow}`;
  data.forEach((item) => {
    let channel = item.channel.name;
    item.payloads.forEach((store) => {
      csvData += `"${channel}","${store.number}","${store.name}",`;
      store.payloads.forEach((day) => {
        csvData += `${day.viewer},${day.ots},`;
      });
      csvData = csvData.replace(/,$/i, '\r\n');
    });
  });
  return csvData;
};

const initialState = {
  store_data: getInitState(),
  interval: null,
};

const operation = (state = initialState, action) => {
  const { type, payload, error } = action;
  const initialValue = {
    [createType(UNAUTHENTICATE, REQUEST)]: () => initialState,
    [createType(SET, ACCOUNT)]: () => initialState,
    [createType(OPERATION_ANALYT, SET, INTERVAL)]: () => ({ ...state, interval: payload }),
    [createType(OPERATION_ANALYT, DATA, REQUEST)]: () => ({
      ...state,
      store_data: getLoadingState(),
    }),
    [createType(OPERATION_ANALYT, DATA, RESPONSE)]: () => {
      if (error) {
        return { ...state, store_data: { ...getErrorState(), error: payload.message } };
      }

      if (payload.storeday.status !== 'OK') {
        return { ...state, store_data: { ...getErrorState(), error: 'Data Error' } };
      }
      exportCSV(prepareData(payload.storeday.payload, state.interval));
      return { ...state, store_data: { ...getReadyState(), data: payload } };
    },
  };
  if (type in initialValue) {
    return initialValue[type](state, action);
  }
  return state;
};

export default operation;
