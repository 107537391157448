import { createStore, applyMiddleware } from 'redux';

import rootReducer from '../reducers';

import { createEpicMiddleware } from 'redux-observable';
import rootEpic from '../epics';

const epicMiddleWare = createEpicMiddleware();

const store = createStore(rootReducer, applyMiddleware(epicMiddleWare));

epicMiddleWare.run(rootEpic);

export default store;
