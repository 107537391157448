import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router';
import { AUTHENTICATED } from '../../common/dict';
import { menuList } from '../../common/menu_list';
import { getUserPages } from '../../common/util';
import NoFound from '../NoFound';
import withLogin from './withLogin';

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.autoLogin();
  }

  render() {
    const auth = this.props.auth;
    let IndexComponent = withLogin(null);

    // figure out authorized pages
    let pages;
    if (auth.status !== AUTHENTICATED) {
      pages = [];
    } else {
      pages = getUserPages(auth.user);
    }

    let pageRoutes = pages.map((page) => (
      <Route
        exact
        path={menuList[page].path}
        key={page}
        component={withLogin(menuList[page].component)}
      />
    ));

    return (
      <Switch>
        <Route exact path="/" key="index" component={IndexComponent} />
        {pageRoutes}
        <Route exact path={'/login'} key={'login'} render={() => <Redirect to={'/'} />} />
        <Route key={'nofound'} component={withLogin(NoFound)} />
      </Switch>
    );
  }
}

export default Routes;

Routes.propTypes = {
  pathname: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  autoLogin: PropTypes.func.isRequired,
};
