export const API_PATH = process.env.REACT_APP_BACKEND_URL;

// container IDs
export const DASHBOARD = 'DASHBOARD';
export const REGION_ANALYT = 'REGION_ANALYT';
export const TIME_ANALYT = 'TIME_ANALYT';
export const PROFILE_ANALYT = 'PROFILE_ANALYT';
export const CAMPAIGN_ANALYT = 'CAMPAIGN_ANALYT';
export const SECOND_ANALYT = 'SECOND_ANALYT';
export const OPERATION_ANALYT = 'OPERATION_ANALYT';
export const PO_ANALYT = 'PO_ANALYT';

// verb
export const SET = 'SET';
export const GET = 'GET';
export const REQUEST = 'REQUEST'; // async
export const RESPONSE = 'RESPONSE'; // async
export const AUTOAUTHENTICATE = 'AUTOAUTHENTICATE';
export const AUTHENTICATE = 'AUTHENTICATE';
export const UNAUTHENTICATE = 'UNAUTHENTICATE';

// noun
export const ACCOUNT = 'ACCOUNT';
export const PO = 'PO';
export const PO_PERIOD = 'PO_PERIOD';
export const PO_LIST = 'PO_LIST';
export const CAMPAIGN = 'CAMPAIGN';
export const CAMPAIGNS = 'CAMPAIGNS';
export const CAMPAIGN_LIST = 'CAMPAIGN_LIST';
export const CHANNEL = 'CHANNEL';
export const CHANNELS = 'CHANNELS';
export const CHANNEL_LIST = 'CHANNEL_LIST';
export const REGION = 'REGION';
export const REGIONS = 'REGIONS';
export const REGION_LIST = 'REGION_LIST';
export const STORE = 'STORE';
export const STORE_LIST = 'STORE_LIST';
export const VIEWERS = 'VIEWERS';
export const EVENTS = 'EVENTS';
export const OTS = 'OTS';
export const INTERVAL = 'INTERVAL';
export const PERIOD = 'PERIOD';
export const DATA = 'DATA';
export const INFO = 'INFO';
export const SELECTOR = 'SELECTOR';
export const LOCALE = 'LOCALE';
export const SECOND = 'SECOND';
export const NAME = 'NAME';

// adjective
export const AGGREGATED = 'AGGREGATED';
export const UNAUTHENTICATED = 'unauthenticated';
export const AUTHENTICATING = 'authenticating';
export const AUTHENTICATED = 'authenticated';

// status
export const INIT = 'INIT';
export const LOADING = 'LOADING';
export const READY = 'READY';
export const ERROR = 'ERROR';

// demographics
export const targetGenders = ['male', 'female'];
export const targetAgeGroups = ['child', 'young', 'youngAdult', 'adult', 'senior',
                                '13-17', '18-29', '30-49', '50-64', '65+'];
