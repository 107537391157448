import { DateTime } from 'luxon';
import {
  PO_ANALYT,
  SET,
  PO,
  PO_PERIOD,
  PO_LIST,
  CAMPAIGNS,
  CAMPAIGN_LIST,
  INTERVAL,
  CHANNEL,
  DATA,
  INFO,
  REQUEST,
  RESPONSE,
  UNAUTHENTICATE,
} from '../common/dict';

import {
  createType,
  aggreWeekly,
  formatDate,
  getToday,
  addIdtoRepeatedName,
  getInitState,
  getLoadingState,
  getReadyState,
  getErrorState,
} from '../common/util';

export const getChannelList = (campaigns) => {
  let data = [];
  let channels = {};
  campaigns.forEach((campaign) => {
    let channel = campaign.channel.id;
    if (!channels[channel]) {
      channels[channel] = true;
      data.push(campaign.channel);
    }
  });
  return data;
};

export const getCampaignPeriod = (campaigns) => {
  let startDate, endDate;
  let yesterday = getToday().minus({ days: 1 });

  campaigns.forEach((campaign) => {
    let s = DateTime.fromISO(campaign.startDate);
    let e = DateTime.fromISO(campaign.endDate);
    if (!startDate || s < startDate) {
      startDate = s;
    }
    if (!endDate || e > endDate) {
      endDate = e;
    }
  });
  // check whether endDate is out of scope
  endDate = yesterday < endDate ? yesterday : endDate;

  return {
    StartDate: formatDate(startDate),
    EndDate: formatDate(endDate.plus({ days: 1 })),
  };
};

export const addtargetReachedView = (payload, allTA) => {
  payload.forEach((item) => {
    if (allTA.length === 0) {
      item.targetViewReached = item.viewer;
    } else {
      let targetReachedView = 0;
      for (let key in item) {
        if (allTA.indexOf(key) > -1) {
          targetReachedView += item[key];
        }
        item.targetViewReached = targetReachedView;
      }
    }
  });
};

const initialState = {
  po_period: 90,
  po: undefined,
  campaigns: [],
  channel: undefined,
  po_list: getInitState(),
  campaign_list: getInitState(),
  channel_list: getInitState(),
  poreport_data: getInitState(),
  poreportInfo: {
    playHour: undefined,
    campaignNames: [],
    channelName: undefined,
    campaignPeriod: undefined,
    period: undefined,
  },
  weeks: [],
  interval: null,
};

const poreport = (state = initialState, action) => {
  const { type, payload, error } = action;
  const initialValue = {
    [createType(UNAUTHENTICATE, REQUEST)]: () => initialState,
    [createType(PO_ANALYT, SET, PO_PERIOD)]: () => {
      const newState = {
        ...state,
        po_period: payload,
        campaigns: [],
        po_list: getInitState(),
        campaign_list: getInitState(),
        channel_list: getInitState(),
      };
      delete newState.po;
      delete newState.channel;
      return newState;
    },
    [createType(PO_ANALYT, SET, PO)]: () => {
      const newState = {
        ...state,
        po: payload,
        campaigns: [],
        campaign_list: getInitState(),
        channel_list: getInitState(),
      };
      delete newState.channel;
      return newState;
    },
    [createType(PO_ANALYT, SET, CAMPAIGNS)]: () => {
      const newState = {
        ...state,
        campaigns: payload,
        channel_list: { ...getReadyState(), data: getChannelList(payload) },
        interval: payload && payload.length !== 0 ? getCampaignPeriod(payload) : state.interval,
      };
      delete newState.channel;
      return newState;
    },
    [createType(PO_ANALYT, SET, CHANNEL)]: () => ({ ...state, channel: payload }),
    [createType(PO_ANALYT, SET, INTERVAL)]: () => ({ ...state, interval: payload }),
    [createType(PO_ANALYT, SET, INFO)]: () => ({ ...state, poreportInfo: payload }),
    [createType(PO_ANALYT, PO_LIST, REQUEST)]: () => ({ ...state, po_list: getLoadingState() }),
    [createType(PO_ANALYT, PO_LIST, RESPONSE)]: () => {
      if (error) {
        return { ...state, po_list: { ...getErrorState(), error: payload.message } };
      }
      return { ...state, po_list: { ...getReadyState(), data: addIdtoRepeatedName(payload) } };
    },
    [createType(PO_ANALYT, CAMPAIGN_LIST, REQUEST)]: () => ({
      ...state,
      campaign_list: getLoadingState(),
    }),
    [createType(PO_ANALYT, CAMPAIGN_LIST, RESPONSE)]: () => {
      if (error) {
        return { ...state, campaign_list: { ...getErrorState(), error: payload.message } };
      }
      return { ...state, campaign_list: { ...getReadyState(), data: payload } };
    },
    [createType(PO_ANALYT, DATA, REQUEST)]: () => ({ ...state, poreport_data: getLoadingState() }),
    [createType(PO_ANALYT, DATA, RESPONSE)]: () => {
      if (error) {
        return { ...state, poreport_data: { ...getErrorState(), error: payload.message } };
      }
      let group = [],
        allTA = [];
      let targetView = 0;
      if (payload.target.status === 'OK') {
        group = payload.target.payload.reduce((acc, cur) => {
          if (cur.group.length > 0) {
            acc.push(cur.group);
          }
          return acc;
        }, []);
        allTA = group.reduce((acc, cur) => acc.concat(cur), []);
        targetView = payload.target.payload.reduce(
          (acc, cur) => acc + cur.paidView + cur.promotionalView,
          0,
        );
      }
      payload.target.payload = { group, targetView };

      // count targetViewReached
      if (payload.day.status === 'OK') {
        addtargetReachedView(payload.day.payload, allTA);
      }
      if (payload.hour.status === 'OK') {
        addtargetReachedView(payload.hour.payload, allTA);
      }

      return {
        ...state,
        poreport_data: { ...getReadyState(), data: payload },
        weeks: aggreWeekly(action),
      };
    },
  };
  if (type in initialValue) {
    return initialValue[type]();
  }
  return state;
};

export default poreport;
