import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => (
  <div className={'defaultItemContainer'}>
    <CircularProgress size={25} />
  </div>
);

export default Loading;
