import React from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { HashRouter } from 'react-router-dom';

import RouteContainer from './RouteContainer';
import store from '../common/store';

export default function Root() {
  const theme = createMuiTheme({
    palette: {
      primary: { main: '#00bcd4', contrastText: '#fff' },
    },
    overrides: {
      MuiButton: {
        contained: {
          backgroundColor: 'white',
          borderRadius: 0,
        },
      },
    },
  });
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <HashRouter>
          <RouteContainer />
        </HashRouter>
      </MuiThemeProvider>
    </Provider>
  );
}
