import { combineEpics } from 'redux-observable';
import { createType, fetchResponse, zipToObj } from '../common/util';
import { REGION_ANALYT, CHANNELS, AGGREGATED, REGION, REQUEST, RESPONSE } from '../common/dict';
import { regionInsightUri, storeListUri } from '../actions/api';

import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { forkJoin, zip, throwError } from 'rxjs/index';

export const channelsRegion = (action$) => {
  let prefix = createType(REGION_ANALYT, CHANNELS, AGGREGATED, REGION);
  return action$.pipe(
    ofType(createType(prefix, REQUEST)),
    switchMap((action) =>
      zip(
        of(action.payload.channelIDs),
        forkJoin(
          action.payload.channelIDs.map((channelID) => {
            let query = {
              StartDate: action.payload.startDate,
              EndDate: action.payload.endDate,
              Account: action.payload.account,
              Channel: channelID,
            };
            return fetchResponse(regionInsightUri, query).pipe(
              mergeMap((res) => {
                if (res.payload.region.payload.length === 0) {
                  return of([]);
                }
                return forkJoin(
                  res.payload.region.payload.map((region) => {
                    let q = {
                      Account: action.payload.account,
                      Channel: channelID,
                      Region: region.id,
                      Active: true,
                    };
                    return fetchResponse(storeListUri, q).pipe(
                      map((res) => Object.assign({}, region, { nstores: res.payload.length })), // add stores num
                      catchError((_) => of(region)),
                    );
                  }),
                );
              }),
              catchError((err) =>
                throwError(new Error(`Failed to fetch region: ${err.xhr.response.message}`)),
              ),
            );
          }),
        ),
      ),
    ),
    map((data) => ({
      type: createType(prefix, RESPONSE),
      payload: zipToObj(data[0], data[1]),
    })),
    catchError((err) =>
      of({
        type: createType(prefix, RESPONSE),
        error: true,
        payload: err,
      }),
    ),
  );
};

const region = combineEpics(channelsRegion);

export default region;
