import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
    width: 105,
    boxSizing: 'border-box',
  },
  label: {
    height: 10,
    marginBottom: 10,
    fontSize: '0,8em',
    color: '#444444',
  },
  logoSM: {
    width: 85,
    height: 30,
  },
  logo: {
    height: 40,
  },
};

class Logo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.url) {
      return (
        <div style={styles.container}>
          <img style={styles.logo} alt="Logo" src={this.props.url} />
        </div>
      );
    }

    return (
      <div style={styles.container}>
        <label style={styles.label}>powered by</label>
        <img style={styles.logoSM} alt="Logo" src={'./img/PTV.png'} />
      </div>
    );
  }
}

export default Logo;

Logo.propTypes = {
  url: PropTypes.string,
};
