import {
  CAMPAIGN_ANALYT,
  SET,
  CAMPAIGN,
  CAMPAIGN_LIST,
  CHANNEL,
  DATA,
  INTERVAL,
  ACCOUNT,
  REQUEST,
  RESPONSE,
  UNAUTHENTICATE,
  INFO,
} from '../common/dict';

import {
  createType,
  defineAsyncReducer,
  aggreWeekly,
  addIdtoRepeatedName,
  getInitState,
  getLoadingState,
  getErrorState,
  getReadyState,
} from '../common/util';

const campaign_data = defineAsyncReducer(createType(CAMPAIGN_ANALYT, DATA), 'campaign_data');

const initialState = {
  campaign: undefined,
  channel: undefined,
  campaign_list: getInitState(),
  campaign_data: getInitState(),
  campaignInfo: {
    campaignName: undefined,
    channelName: undefined,
    period: undefined,
  },
  weeks: [],
  interval: null,
};

const campaign = (state = initialState, action) => {
  const { type, payload, error } = action;
  const initialValue = {
    [createType(UNAUTHENTICATE, REQUEST)]: () => initialState,
    [createType(SET, ACCOUNT)]: () => initialState,
    [createType(CAMPAIGN_ANALYT, SET, CAMPAIGN)]: () => ({ ...state, campaign: payload }),
    [createType(CAMPAIGN_ANALYT, SET, CHANNEL)]: () => ({ ...state, channel: payload }),
    [createType(CAMPAIGN_ANALYT, SET, INTERVAL)]: () => ({ ...state, interval: payload }),
    [createType(CAMPAIGN_ANALYT, SET, INFO)]: () => ({ ...state, campaignInfo: payload }),
    [createType(CAMPAIGN_ANALYT, CAMPAIGN_LIST, REQUEST)]: () => ({
      ...state,
      campaign_list: getLoadingState(),
    }),
    [createType(CAMPAIGN_ANALYT, CAMPAIGN_LIST, RESPONSE)]: () => {
      if (error) {
        return { ...state, campaign_list: { ...getErrorState(), error: payload.message } };
      }
      return {
        ...state,
        campaign_list: { ...getReadyState(), data: addIdtoRepeatedName(payload) },
      };
    },
  };
  const reducers = [campaign_data].reduce(
    (accumulator, currentValue) => currentValue(accumulator),
    initialValue,
  );
  const key = createType(CAMPAIGN_ANALYT, DATA, RESPONSE);
  const f = reducers[key];
  reducers[key] = (state, action) => f({ ...state, weeks: aggreWeekly(action) }, action);
  if (type in reducers) {
    return reducers[type](state, action);
  }
  return state;
};

export default campaign;
