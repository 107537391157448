import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getMsgs, addColon } from '../common/util';

import { login } from '../actions/aaa';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const styles = {
  container: {
    width: 400,
    padding: 30,
    marginTop: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
  },
  header: {
    fontSize: '1.5em',
    padding: 0,
    paddingLeft: 5,
    margin: 0,
  },
  line: {
    height: '2px',
    marginBottom: 15,
    background: 'linear-gradient(to right, #171717, #FFFFFF)',
  },
  row: {
    marginTop: 0,
  },
  title: {
    width: 100,
    textAlign: 'right',
  },
  input: {
    width: 220,
  },
  errMsg: {
    width: '100%',
    height: 17,
    textAlign: 'right',
    margin: 0,
    color: '#CC0000',
    fontSize: '0.9em',
  },
  btnArea: {
    textAlign: 'right',
    marginTop: 10,
  },
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
    };
  }

  render() {
    const authentication = this.props.authentication;
    const loginable = this.state.username && this.state.password;
    const errMsg = !authentication.error
      ? null
      : authentication.error === 'invalidToken'
      ? this.props.intl.formatMessage(getMsgs('invalidToken'))
      : this.props.intl.formatMessage(getMsgs('errMsg'));

    return (
      <div
        style={styles.container}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && loginable) {
            this.props.login(this.state.username, this.state.password);
          }
        }}
      >
        <h1 style={styles.header}>{this.props.intl.formatMessage(getMsgs('titleLogin'))}</h1>
        <div style={styles.line}></div>
        <div className={'firstRow'}>
          <label htmlFor={'username'} style={styles.title}>
            {addColon('username', this.props.intl.formatMessage)}
          </label>
          <TextField
            id={'username'}
            style={styles.input}
            placeholder={this.props.intl.formatMessage(getMsgs('username'))}
            onChange={(e) => this.setState({ username: e.target.value })}
          />
        </div>
        <p style={styles.errMsg}>{errMsg}</p>
        <div style={styles.row} className={'row'}>
          <label htmlFor={'pw'} style={styles.title}>
            {addColon('password', this.props.intl.formatMessage)}
          </label>
          <TextField
            id={'pw'}
            style={styles.input}
            placeholder={this.props.intl.formatMessage(getMsgs('password'))}
            type="password"
            onChange={(e) => this.setState({ password: e.target.value })}
          />
        </div>
        <p style={styles.errMsg}>{errMsg}</p>
        <div style={styles.btnArea}>
          <Button
            variant="contained"
            disabled={!loginable}
            color="primary"
            onClick={(e) => this.props.login(this.state.username, this.state.password)}
          >
            {this.props.intl.formatMessage(getMsgs('login'))}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: state.shared.authentication,
  };
};

const mapDispatchToProps = { login };

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login));

Login.propTypes = {
  intl: intlShape.isRequired,
  authentication: PropTypes.object,
  login: PropTypes.func.isRequired,
};
