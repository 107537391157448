import { combineEpics } from 'redux-observable';
import { createType, fetchResponse, genFetchEpic, genFetchPostEpic } from '../common/util';
import { PO_ANALYT, PO_LIST, CAMPAIGN_LIST, DATA, REQUEST, RESPONSE } from '../common/dict';
import { poListUri, poCampaignListUri, poreportInsightUri } from '../actions/api';

import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

const poList = genFetchEpic(createType(PO_ANALYT, PO_LIST), poListUri);

export const campaignList = (action$) =>
  action$.pipe(
    ofType(createType(PO_ANALYT, CAMPAIGN_LIST, REQUEST)),
    switchMap((action) =>
      fetchResponse(poCampaignListUri(action.payload)).pipe(
        map((res) => ({
          type: createType(PO_ANALYT, CAMPAIGN_LIST, RESPONSE),
          payload: res.payload,
        })),
        catchError((err) =>
          of({
            type: createType(PO_ANALYT, CAMPAIGN_LIST, RESPONSE),
            error: true,
            payload: err.xhr.response,
          }),
        ),
      ),
    ),
  );

const poreportData = genFetchPostEpic(createType(PO_ANALYT, DATA), poreportInsightUri);

const poreport = combineEpics(poList, campaignList, poreportData);

export default poreport;
