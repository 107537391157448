import React from 'react';
import { connect } from 'react-redux';
import { AUTHENTICATED, INIT } from '../../common/dict';
import { menuList } from '../../common/menu_list';
import { getUserPages } from '../../common/util';
import Login from '../../containers/Login';
import Loading from '../general/Loading';
import ErrorMsg from '../general/Error';

export default function withLogin(WrappedComponent = null) {
  class Authentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.getIndexComponent = this.getIndexComponent.bind(this);
    }

    getIndexComponent(user) {
      const userPages = getUserPages(user);
      if (userPages.indexOf('dashboard') > -1) {
        return menuList['dashboard'].component;
      } else if (userPages.indexOf('dashboardRaw') > -1) {
        return menuList['dashboardRaw'].component;
      } else if (userPages.length > 0) {
        return menuList[userPages[0]].component;
      } else {
        return ErrorMsg;
      }
    }

    render() {
      const auth = this.props.authentication;
      if (auth.status === INIT) {
        return <Loading />;
      } else if (auth.status !== AUTHENTICATED) {
        return <Login />;
      } else {
        let Component = WrappedComponent || this.getIndexComponent(auth.user);
        return <Component />;
      }
    }
  }

  const mapStateToProps = (state) => ({
    authentication: state.shared.authentication,
  });

  return connect(mapStateToProps)(Authentication);
}
