import { combineEpics } from 'redux-observable';
import { createType, genFetchEpic } from '../common/util';
import { DASHBOARD, STORE_LIST, VIEWERS, EVENTS } from '../common/dict';
import { storeListUri, dashboardInsightUri, rawDashboardInsightUri,
       } from '../actions/api';

const storeList = genFetchEpic(createType(DASHBOARD, STORE_LIST), storeListUri);
const viewers = genFetchEpic(createType(DASHBOARD, VIEWERS), dashboardInsightUri);
const events = genFetchEpic(createType(DASHBOARD, EVENTS), rawDashboardInsightUri);

const dashboard = combineEpics(storeList, viewers, events);

export default dashboard;
