import { API_PATH } from '../common/dict';

export const userInfoUri = `${API_PATH}/user`;
export const loginUri = `${API_PATH}/login`;
export const logoutUri = `${API_PATH}/logout`;

export const channelListUri = `${API_PATH}/channel`;
export const storeListUri = `${API_PATH}/store`;
export const regionListUri = `${API_PATH}/region`;
export const campaignListUri = `${API_PATH}/campaign`;
export const poListUri = `${API_PATH}/po`;
export const poCampaignListUri = (poid) => `${API_PATH}/po/${poid}/media`;
export const campaignChannelListUri = (cid) => `${API_PATH}/campaign/${cid}/channel`;

export const dashboardInsightUri = `${API_PATH}/insight/dashboard`;
export const rawDashboardInsightUri = `${API_PATH}/raw/insight/dashboard`;
export const regionInsightUri = `${API_PATH}/insight/region`;
export const timeInsightUri = `${API_PATH}/insight/time`;
export const rawTimeInsightUri = `${API_PATH}/raw/insight/time`;
export const profileInsightUri = `${API_PATH}/insight/profile`;
export const rawProfileInsightUri = `${API_PATH}/raw/insight/profile`;
export const campaignInsightUri = `${API_PATH}/insight/campaign`;
export const secondInsightUri = `${API_PATH}/insight/second`;
export const operationInsightUri = `${API_PATH}/insight/operation`;
export const poreportInsightUri = `${API_PATH}/insight/poreport`;
