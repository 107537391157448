import { SET, ACCOUNT, LOCALE } from '../common/dict';
import { createType } from '../common/util';

export const setAccount = (payload) => ({
  type: createType(SET, ACCOUNT),
  payload,
});

export const setLocale = (payload) => ({
  type: createType(SET, LOCALE),
  payload,
});
