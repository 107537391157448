import {
  REGION_ANALYT,
  SET,
  CHANNELS,
  REGION,
  INTERVAL,
  AGGREGATED,
  ACCOUNT,
  UNAUTHENTICATE,
  REQUEST,
} from '../common/dict';

import {
  createType,
  getInitState,
  defineAsyncReducer,
} from '../common/util';

const channelRegionTable = defineAsyncReducer(
  createType(REGION_ANALYT, CHANNELS, AGGREGATED, REGION),
  'channel_region_table',
);

const initialState = {
  channels: [],
  interval: null,
  channel_region_table: getInitState(),
};

const region = (state = initialState, action) => {
  const { type, payload } = action;
  const initialValue = {
    [createType(UNAUTHENTICATE, REQUEST)]: () => initialState,
    [createType(SET, ACCOUNT)]: () => initialState,
    [createType(REGION_ANALYT, SET, CHANNELS)]: () => ({ ...state, channels: payload }),
    [createType(REGION_ANALYT, SET, INTERVAL)]: () => ({ ...state, interval: payload }),
  };
  const reducers = [channelRegionTable].reduce(
    (accumulator, currentValue) => currentValue(accumulator),
    initialValue,
  );
  if (type in reducers) {
    return reducers[type](state, action);
  }
  return state;
};

export default region;
