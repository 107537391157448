import {
  REQUEST,
  RESPONSE,
  VIEWERS,
  EVENTS,
  DATA,
  PO_ANALYT,
  DASHBOARD,
  PROFILE_ANALYT,
  TIME_ANALYT,
  CAMPAIGN_ANALYT,
  SECOND_ANALYT,
  AUTOAUTHENTICATE,
  AUTHENTICATE,
  UNAUTHENTICATE,
  UNAUTHENTICATED,
  AUTHENTICATING,
  AUTHENTICATED,
  SET,
  ACCOUNT,
  LOCALE,
  targetGenders,
  targetAgeGroups,
} from '../common/dict';

import { createType, getInitState, camelCase } from '../common/util';

const getUnauthState = () => ({ status: UNAUTHENTICATED });
const getAuthingState = () => ({ status: AUTHENTICATING });
const getAuthedState = () => ({ status: AUTHENTICATED });

const DEMO_INIT_STATS = {
  genders: targetGenders.slice(),
  ageGroups: targetAgeGroups.slice(0, 5),
  groups: targetGenders
    .map((gender) => targetAgeGroups.slice(0, 5).map(
      (ageGroup) => camelCase(gender, ageGroup)))
    .reduce((accumulator, currentValue) => accumulator.concat(currentValue), []),
};

export const extractDemographicInfo = (sample) => {
  let genders = new Set();
  let ageGroups = new Set();
  let groups = [];
  targetGenders.forEach((gender) => {
    targetAgeGroups.forEach((ageGroup) => {
      let group = camelCase(gender, ageGroup);
      if (group in sample) {
        genders.add(gender);
        ageGroups.add(ageGroup);
        groups.push(group);
      }
    });
  });
  return {
    genders: Array.from(genders),
    ageGroups: Array.from(ageGroups),
    groups,
  };
};

export const extractDemographicInfoFromMultiOptions = (state, action) => {
  let { error, payload } = action;
  if (error === true) {
    return state;
  }

  for (let key in payload) {
    let result = extractDemographicInfoFromData(state, { payload: payload[key] });
    if (result !== state) {
      return result;
    }
  }
  return state;
};

export const extractDemographicInfoFromData = (state, action) => {
  let { error, payload } = action;
  if (error === true) {
    return state;
  }

  for (let key in payload) {
    if (
      payload[key].status === 'OK' &&
      Array.isArray(payload[key].payload) &&
      payload[key].payload.length > 0
    ) {
      return {
        ...state,
        demographics: extractDemographicInfo(payload[key].payload[0]),
      };
    }
  }
  return state;
};

export const getStateAfterAuthenticated = (state, payload) => {
  const channel_mapping = payload.accounts.reduce((pre, cur) => {
    pre[cur.id] = { status: 'READY', data: cur.channels };
    return pre;
  }, {});

  let newState = {
    ...state,
    authentication: { ...getAuthedState(), user: payload },
    account: payload.accounts[0].id,
    channel_mapping,
  };
  const oldAccount = state.account;
  if (oldAccount) {
    // check whether user still have the authority to access this account
    payload.accounts.some((account) => {
      if (account.id === oldAccount) {
        newState = {
          ...newState,
          account: oldAccount,
        };
        return true;
      }
      return false;
    });
  }

  // don't need to set advertiser for admin
  if (payload.advertiser && !payload.admin) {
    newState = {
      ...newState,
      advertiser: payload.advertiser,
    };
  }
  return newState;
};

export const getStateAfterUnauthenticated = (state, errMsg = null) => {
  // only keep locale
  const authentication = errMsg ? { ...getUnauthState(), error: errMsg } : getUnauthState();
  const newState = {
    ...initialState,
    locale: state.locale,
    authentication,
  };
  return newState;
};

const initialState = {
  demographics: DEMO_INIT_STATS,
  authentication: getInitState(),
  account: undefined,
  locale: 'en',
  advertiser: undefined,
};

const shared = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    // get demographic data
    case createType(DASHBOARD, VIEWERS, RESPONSE):
    case createType(DASHBOARD, EVENTS, RESPONSE):
    case createType(PROFILE_ANALYT, VIEWERS, RESPONSE):
    case createType(PROFILE_ANALYT, EVENTS, RESPONSE):
    case createType(CAMPAIGN_ANALYT, DATA, RESPONSE):
    case createType(SECOND_ANALYT, DATA, RESPONSE):
    case createType(PO_ANALYT, DATA, RESPONSE):
      return extractDemographicInfoFromData(state, action);
    case createType(TIME_ANALYT, VIEWERS, RESPONSE):
    case createType(TIME_ANALYT, EVENTS, RESPONSE):
      return extractDemographicInfoFromMultiOptions(state, action);
    // authenticate user
    case createType(AUTHENTICATE, REQUEST):
      return { ...state, authentication: getAuthingState() };
    case createType(AUTOAUTHENTICATE, REQUEST):
      return { ...state }; // keep original state when it is auto-login
    case createType(AUTHENTICATE, RESPONSE):
      if (error) {
        return getStateAfterUnauthenticated(state, payload.message);
      }
      if (!('name' in payload)) {
        return getStateAfterUnauthenticated(state, 'Invalid Username');
      }
      // only reset locale when manually login
      return { ...getStateAfterAuthenticated(state, payload), locale: payload.lang || 'en' };
    case createType(AUTOAUTHENTICATE, RESPONSE):
      if (error || !('name' in payload)) {
        // set back to initialState
        return getStateAfterUnauthenticated(state);
      }
      return getStateAfterAuthenticated(state, payload);
    case createType(UNAUTHENTICATE, REQUEST):
      return getStateAfterUnauthenticated(state);
    // set account and locale
    case createType(SET, ACCOUNT):
      return { ...state, demographics: DEMO_INIT_STATS, account: payload };
    case createType(SET, LOCALE):
      return { ...state, locale: payload };
    case createType(UNAUTHENTICATE, ACCOUNT):
      return getStateAfterUnauthenticated(state, 'invalidToken');
    default:
      return state;
  }
};

export default shared;
