import { combineEpics } from 'redux-observable';
import aaa from './aaa';
import dashboard from './dashboard';
import region from './region';
import time from './time';
import profile from './profile';
import campaign from './campaign';
import second from './second';
import operation from './operation';
import poreport from './poreport';

const rootEpic = combineEpics(
  aaa,
  dashboard,
  region,
  time,
  profile,
  campaign,
  second,
  operation,
  poreport,
);

export default rootEpic;
